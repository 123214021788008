/* stylelint-disable */

@mixin text-code {
    font-family: monospace;
    font-size: 90%;
    font-weight: normal;
}

@mixin build-icon($color) {
    color: $color;
    transition: color .3s ease;

    &:hover,
    &:focus {
        color: darken($color, 10%);
    }
}

@mixin build-toggle-button($color, $background) {
    background: $background;
    border-color: $color;
    color: $color;
    z-index: 1000;
}

@mixin build-text-button($color) {
    color: $color;

    &:disabled,
    &.disabled {
        opacity: .4;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }

    &:active,
    &:hover {
        color: darken($color, 15%);
    }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    &::-webkit-scrollbar {
        height: $size;
        width:  $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    scrollbar-color: $foreground-color $background-color;
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
    scrollbar-width: thin;
}

@mixin clearfix {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

@mixin hidden {
    opacity: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    position: absolute;
    width: 0;
}

@mixin force-width($width) {
    // Ensure that we use the minimum width in flex scenarios.
    max-width: $width;
    min-width: $width;
    // Normal width definition.
    width: $width;
}

@mixin force-height($height) {
    // Normal height definition.
    height: $height;
    // Ensure that we use the minimum height in flex scenarios.
    max-height: $height;
    min-height: $height;
}

@mixin caret-top($color, $size: .6rem) {
    @include caret;
    border: $size solid transparent;
    border-bottom-color: $color;
}

@mixin caret-left($color, $size: .6rem) {
    @include caret;
    border: $size solid transparent;
    border-right-color: $color;
}

@mixin caret-right($color, $size: .6rem) {
    @include caret;
    border: $size solid transparent;
    border-left-color: $color;
}

@mixin caret-bottom($color, $size: .6rem) {
    @include caret;
    border: $size solid transparent;
    border-top-color: $color;
}

@mixin caret {
    @include force-height(0);
    @include force-width(0);
    content: '';
    display: inline-block;
}

@mixin circle($size) {
    @include force-height($size);
    @include force-width($size);
    border-radius: $size;
    border-width: 0;
    display: inline-block;
}

@mixin circle-icon($size) {
    @include circle($size);
    cursor: none;
    font-size: .5 * $size;
    font-weight: normal;
    line-height: $size + .1rem;
    text-align: center;
}

@mixin placeholder-color($color) {
    ::placeholder {
        color: $color;
    }

    &::-webkit-input-placeholder {
        color: $color;
    }

    &:-moz-placeholder {
        color: $color;
        opacity: 1;
    }

    &::-moz-placeholder {
        color: $color;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        color: $color;
    }
}

@mixin hover-visible($selector, $display: block) {
    #{$selector} {
        display: none;
    }

    &:hover {
        #{$selector} {
            display: $display;
        }
    }
}

@mixin box-shadow-outer($x-axis: 0, $y-axis: 1px, $blur: 2px, $alpha: .1) {
    box-shadow: $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
}

@mixin box-shadow-inner($x-axis: 0, $y-axis: 1px, $blur: 2px, $alpha: .1) {
    box-shadow: inset $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
}

@mixin position($t: null, $r: null, $b: null, $l: null) {
    bottom: $b; left: $l; right: $r; top: $t;
}

@mixin absolute($t: null, $r: null, $b: null, $l: null) {
    @include position($t, $r, $b, $l);
    position: absolute;
}

@mixin fixed($t: null, $r: null, $b: null, $l: null) {
    @include position($t, $r, $b, $l);
    position: fixed;
}

@mixin truncate-nowidth {
    display: block;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin truncate {
    @include truncate-nowidth;
    max-width: 100%;
    min-width: 0;
    width: auto;
}

@mixin text-variable {
    @include truncate-nowidth;
    display: inline-block;
    font-size: inherit;
    font-weight: 500;
    max-width: 160px;
    vertical-align: top;
}

@mixin no-drag {
    -webkit-user-drag: none;
}