@import 'mixins';
@import 'vars';

/* stylelint-disable */

//
// Support for Angular validation states.
// 
.form-control {
    &.ng-invalid {
        &.ng-touched,
        &.ng-dirty {
            & {
                border-color: $color-theme-error;
            }

            &:hover,
            &:focus {
                border-color: $color-theme-error-dark;
                box-shadow: 0 0 .2rem, $color-theme-error;
            }
        }
    }

    &.preview {
        background-color: $color-input;
        border-color: $color-input;
        border-radius: $border-radius;
        opacity: .4;
        pointer-events: none;
    }
}

//
// Error tooltip.
//
.errors {
    // Container where the tooltip is placed.
    &-container {
        position: relative;
    }

    // Small triangle under the error tooltip with the border trick.
    &::after {
        @include absolute(null, null, -.75rem, .625rem);
        @include caret-bottom($color-theme-error, .4rem);
    }

    // The tooltip rectangle itself.
    & {
        @include absolute(null, null, .4rem, 0);
        background: $color-theme-error;
        border: 0;
        border-radius: .5 * $border-radius;
        color: $color-white;
        font-size: .9rem;
        font-weight: normal;
        padding: .25rem .5rem;
    }
}

//
// Align labels to the right.
//
.col-form-label,
.col-form-checkbox-label {
    text-align: right;
}

//
// Form alerts.
//
.form-alert {
    & {
        border: 0;
        border-radius: $border-radius;
        color: $color-white;
        font-size: .9rem;
        font-weight: normal;
        margin-bottom: .75rem;
        margin-top: .25rem;
        padding: .5rem;
        padding-right: 1.5rem;
    }

    &-close {
        @include absolute(0, 0, auto, auto);
        display: none;
        padding: .5rem;
    }

    &-error {
        background: $color-theme-error;
    }

    &-success {
        background: $color-theme-success-dark;
    }

    &.closeable {
        position: relative;

        .form-alert-close {
            display: inline-block;
        }
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        margin: 0;
    }
}

.form-bubble {
    & {
        position: relative;
    }

    .form-alert {
        font-size: .9rem;
        font-weight: normal;
        padding: 1rem;
        padding-right: 2.5rem;
        width: max-content;
        z-index: 2000;

        div {
            max-width: 400px;
            min-width: 250px;
        }

        &::after {
            @include absolute(-.75rem, .625rem, auto, auto);
            @include caret-top($color-theme-error, .4rem);
        }
    }
}


//
// Control Dropdown item
//
.control-dropdown {
    & {
        @include absolute(2px, auto, auto, 0);
        @include box-shadow-outer;
        background: $color-white;
        border: 1px solid $color-input;
        border-radius: $border-radius;
        max-height: 15rem;
        overflow-x: hidden;
        overflow-y: auto;
        padding: .25rem 0;
    }

    &-item {
        & {
            padding: .5rem .75rem;
        }

        &.separated {
            border-bottom: 1px solid lighten($color-input, 5%);

            &:last-child {
                border: 0;
            }
        }

        &.active,
        &:active,
        &:hover {
            color: $color-white;

            .text-muted {
                color: $color-white !important;
            }

            i {
                color: $color-white !important;
            }
        }

        &:active,
        &.active {
            background: $color-theme-brand;

            &.separated {
                border-color: $color-theme-brand;
            }
        }

        &:hover {
            background: $color-theme-brand;

            &.separated {
                border-color: $color-theme-brand;
            }
        }

        &-selectable {
            & {
                cursor: pointer;
            }
        }
    }
}

.btn-block {
    width: 100%;
}

//
// Form group error.
//
.form-group {
    margin-bottom: 1.25rem;

    // Remove the margin after the last form group.
    &:last-child {
        margin-bottom: 0;
    }

    // Remove the margin if the next element is hidden.
    & ~ .hidden {
        margin-bottom: 0;
    }
}

.col-form-label {
    font-size: 90%;
}
label {
    & {
        font-size: 90%;
        font-weight: 500;
    }

    &.form-check-label {
        font-size: 100%;
        font-weight: normal;
    }

    .hint {
        color: $color-text-decent;
    }

    .text-muted {
        font-weight: normal;
    }
}

//
// Custom search form.
//
.search-form {
    & {
        flex-grow: 1;
        position: relative;
    }

    // Keep some additional space for the search icon.
    .form-control {
        padding-right: 3rem;
    }

    // Search icon that is placed within the form control.
    .icon-search {
        @include absolute(.75rem, .75rem, auto, auto);
        color: $color-input;
        font-size: 1.1rem;
        font-weight: lighter;
    }
}

input {
    &.form-empty {
        border: 0;
    }
}